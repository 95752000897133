"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.endpointAliases = void 0;
exports.endpointAliases = {
    adminGetUsers: ["get", "/admin/users", true],
    adminGetFacilities: ["get", "/admin/facilities", true],
    adminGetUserToken: ["get", "/admin/user/{userId}/token", true],
    adminUserAddFacility: ["put", "/admin/user/{userId}/facilities/{facilityId}", true],
    adminUserRemoveFacility: ["delete", "/admin/user/{userId}/facilities/{facilityId}", true],
    getAgent: ["get", "/agent/{agentId}", true],
    updateAgent: ["put", "/agent/{agentId}", true],
    deleteAgent: ["delete", "/agent/{agentId}", true],
    getBooking: ["get", "/bookings/{bookingId}", true],
    deleteBooking: ["delete", "/bookings/{bookingId}", true],
    patchBooking: ["patch", "/bookings/{bookingId}", true],
    rescheduleBooking: ["put", "/bookings/{bookingId}/reschedule", true],
    addBookingItem: ["post", "/bookings/{bookingId}/item", true],
    addBookingItems: ["post", "/bookings/{bookingId}/items", true],
    updateBookingItemsForVariant: ["put", "/bookings/{bookingId}/items/{variantId}/{periodId}", true],
    addPenaltyToBooking: ["post", "/bookings/{bookingId}/penalty", true],
    addDiscountToBooking: ["post", "/bookings/{bookingId}/discount", true],
    addPaymentToBooking: ["post", "/bookings/{bookingId}/payment", true],
    addDepositToBooking: ["post", "/bookings/{bookingId}/deposit", true],
    deleteBookingInvoiceItem: ["delete", "/bookings/{bookingId}/invoiceItem/{invoiceItemId}", true],
    deliverBooking: ["put", "/bookings/{bookingId}/deliver", true],
    returnBooking: ["put", "/bookings/{bookingId}/return", true],
    confirmBooking: ["put", "/bookings/{bookingId}/confirm", true],
    unconfirmBooking: ["put", "/bookings/{bookingId}/unconfirm", true],
    cancelBooking: ["put", "/bookings/{bookingId}/cancel", true],
    holdBooking: ["put", "/bookings/{bookingId}/hold", true],
    getBookingDocuments: ["get", "/bookings/{bookingId}/documents", true],
    getSignBookingDocumentsUrl: ["get", "/bookings/{bookingId}/sign-url", true],
    getPaymentLinkUrl: ["get", "/bookings/{bookingId}/payment-link", true],
    removeBookingSignatures: ["delete", "/bookings/{bookingId}/signatures", true],
    sendSignatureForBooking: ["post", "/bookings/{bookingId}/send-signature/{documentId}", true],
    getBookingTraceLog: ["get", "/bookings/{bookingId}/trace-log", true],
    signDocument: ["post", "/bookings/{bookingId}/documents/{documentId}/sign", true],
    createDocumentPoNumber: ["post", "/bookings/{bookingId}/documents/{documentId}/create-po-number", true],
    downloadDocument: ["get", "/bookings/{bookingId}/documents/{documentId}/download", true],
    deleteBookingItem: ["delete", "/booking-item/{bookingItemId}", true],
    assignBookingItem: ["post", "/booking-item/{bookingItemId}/assign/{stockItemId}", true],
    unassignBookingItem: ["put", "/booking-item/{bookingItemId}/unassign", true],
    getApplicableStockItemsforBookingItem: ["get", "/booking-item/{bookingItemId}/stock-items", true],
    updateCustomer: ["put", "/customer/{customerId}", true],
    deleteCustomer: ["delete", "/customer/{customerId}", true],
    updateCustomerField: ["put", "/customer-fields/{fieldId}", true],
    deleteCustomerField: ["delete", "/customer-fields/{fieldId}", true],
    getDocument: ["get", "/documents/{documentId}", true],
    updateDocument: ["put", "/documents/{documentId}", true],
    deleteDocument: ["delete", "/documents/{documentId}", true],
    getExport: ["get", "/export/{exportId}", true],
    updateExport: ["put", "/export/{exportId}", true],
    deleteExport: ["delete", "/export/{exportId}", true],
    downloadExportedFile: ["get", "/export/{exportId}/download", true],
    getExtraCharge: ["get", "/extra-charges/{extraChargeId}", true],
    updateExtraCharge: ["put", "/extra-charges/{extraChargeId}", true],
    deleteExtraCharge: ["delete", "/extra-charges/{extraChargeId}", true],
    getAllFacilitiesForUser: ["get", "/facilities", false],
    getAllAgentsForFacility: ["get", "/facilities/{facilityId}/agents", true],
    createAgent: ["post", "/facilities/{facilityId}/agents", true],
    getBookings: ["get", "/facilities/{facilityId}/bookings", true],
    createBooking: ["post", "/facilities/{facilityId}/bookings", true],
    getBookingStats: ["get", "/facilities/{facilityId}/bookings/stats", true],
    getBookingsBasic: ["get", "/facilities/{facilityId}/bookings/basic", true],
    getFacility: ["get", "/facilities/{facilityId}", true],
    updateFacility: ["put", "/facilities/{facilityId}", true],
    getFacilitySettings: ["get", "/facilities/{facilityId}/settings", true],
    patchFacilitySettings: ["patch", "/facilities/{facilityId}/settings", true],
    getFacilityOpeningHours: ["get", "/facilities/{facilityId}/opening-hours", true],
    updateFacilityOpeningHours: ["put", "/facilities/{facilityId}/opening-hours", true],
    getFastTrackSettings: ["get", "/facilities/{facilityId}/fast-track/settings", true],
    patchFacilityFastTrackSettings: ["patch", "/facilities/{facilityId}/fast-track/settings", true],
    getFastTrackLink: ["get", "/facilities/{facilityId}/fast-track/link", true],
    getStoreSettings: ["get", "/facilities/{facilityId}/store/settings", true],
    patchStoreSettings: ["patch", "/facilities/{facilityId}/store/settings", true],
    getSeasons: ["get", "/facilities/{facilityId}/seasons", true],
    createSeason: ["post", "/facilities/{facilityId}/seasons", true],
    getLocations: ["get", "/facilities/{facilityId}/locations", true],
    createLocation: ["post", "/facilities/{facilityId}/locations", true],
    getCustomerFields: ["get", "/facilities/{facilityId}/customer-fields", true],
    createCustomerField: ["post", "/facilities/{facilityId}/customer-fields", true],
    getCustomers: ["get", "/facilities/{facilityId}/customers", true],
    getAllDocumentsForFacility: ["get", "/facilities/{facilityId}/documents", true],
    createDocument: ["post", "/facilities/{facilityId}/documents", true],
    getDocumentsList: ["get", "/facilities/{facilityId}/documents/list", true],
    getAllExportsForFacility: ["get", "/facilities/{facilityId}/exports", true],
    createExport: ["post", "/facilities/{facilityId}/exports", true],
    getAllExtraChargesForFacility: ["get", "/facilities/{facilityId}/extra-charges", true],
    createExtraCharge: ["post", "/facilities/{facilityId}/extra-charges", true],
    getLanguagesForFacility: ["get", "/facilities/{facilityId}/languages", true],
    updateLanguagesForFacility: ["put", "/facilities/{facilityId}/languages", true],
    getAllProductsForFacility: ["get", "/facilities/{facilityId}/products", true],
    createProduct: ["post", "/facilities/{facilityId}/products", true],
    getAllProductsWithVariantsForFacility: ["get", "/facilities/{facilityId}/products/variants", true],
    reorderProducts: ["put", "/facilities/{facilityId}/products/reorder", true],
    getAllRentalPeriodsForFacility: ["get", "/facilities/{facilityId}/rental-periods", true],
    createRentalPeriod: ["post", "/facilities/{facilityId}/rental-periods", true],
    getReportForProducts: ["get", "/facilities/{facilityId}/report/products", true],
    getReportForPaymentMethods: ["get", "/facilities/{facilityId}/report/payment-methods", true],
    getReportForAgents: ["get", "/facilities/{facilityId}/report/agents", true],
    getAgentFeesReport: ["get", "/facilities/{facilityId}/report/agent-fees", true],
    getStockItemsReport: ["get", "/facilities/{facilityId}/report/stock-items/{productId}", true],
    getAllStockItemVariantsForFacility: ["get", "/facilities/{facilityId}/stock-item-variants", true],
    getTranslationsForLanguage: ["get", "/facilities/{facilityId}/translations/{language}", true],
    updateTranslationsForLanguage: ["put", "/facilities/{facilityId}/translations/{language}", true],
    getUserRightsForFacility: ["get", "/facilities/{facilityId}/user-rights", true],
    enableUserRight: ["put", "/facilities/{facilityId}/user-rights/{userRight}", true],
    disableUserRight: ["delete", "/facilities/{facilityId}/user-rights/{userRight}", true],
    getUsers: ["get", "/facilities/{facilityId}/users", true],
    createUser: ["post", "/facilities/{facilityId}/users", true],
    getFastTrackData: ["get", "/fast-track/data", false],
    fastTrackRedirect: ["get", "/fast-track/redirect", false],
    downloadFastTrackQrCode: ["get", "/fast-track/download/pulso-fast-track-qr.png", false],
    createFastTrackBooking: ["post", "/fast-track/booking", false],
    updateFastTrackBooking: ["put", "/fast-track/booking", false],
    getAvailabilityForFastTrack: ["get", "/fast-track/availability", true],
    getFileContent: ["get", "/file/{token}/{name}", true],
    healthcheck: ["get", "/healthcheck", false],
    updateLocation: ["put", "/location/{locationId}", true],
    deleteLocation: ["delete", "/location/{locationId}", true],
    getMe: ["get", "/me", false],
    getMeRights: ["get", "/me/rights/{facilityId}", true],
    getToken: ["post", "/oauth/token", false],
    getPaymentLinkData: ["get", "/payment-link-data/{bookingId}", true],
    getProduct: ["get", "/products/{productId}", true],
    updateProduct: ["put", "/products/{productId}", true],
    deleteProduct: ["delete", "/products/{productId}", true],
    updatePackageProducts: ["put", "/products/{productId}/package-products", true],
    addComplementForProduct: ["put", "/products/{productId}/complements/{complementId}", true],
    deleteComplementForProduct: ["delete", "/products/{productId}/complements/{complementId}", true],
    addLocationForProduct: ["put", "/products/{productId}/locations/{locationId}", true],
    deleteLocationForProduct: ["delete", "/products/{productId}/locations/{locationId}", true],
    updateProductField: ["put", "/product-fields/{fieldId}", true],
    deleteProductField: ["delete", "/product-fields/{fieldId}", true],
    createProductField: ["post", "/products/{productId}/fields", true],
    getPrices: ["get", "/products/{productId}/prices", true],
    updatePrices: ["put", "/products/{productId}/prices", true],
    getAllStockItemsForProduct: ["get", "/products/{productId}/stock-items", true],
    createStockItems: ["post", "/products/{productId}/stock-items", true],
    updateRentalPeriod: ["put", "/rental-periods/{periodId}", true],
    deleteRentalPeriod: ["delete", "/rental-periods/{periodId}", true],
    updateSeason: ["put", "/season/{seasonId}", true],
    deleteSeason: ["delete", "/season/{seasonId}", true],
    signup: ["post", "/signup", false],
    updateStockItem: ["put", "/stock-items/{stockItemId}", true],
    patchStockItem: ["patch", "/stock-items/{stockItemId}", true],
    deleteStockItem: ["delete", "/stock-items/{stockItemId}", true],
    getStripeConnectedAccount: ["get", "/stripe-connect/{facilityId}/account", true],
    createStripeConnectedAccount: ["post", "/stripe-connect/{facilityId}/account", true],
    disconnectStripe: ["delete", "/stripe-connect/{facilityId}/account", true],
    createStripeAccountSession: ["post", "/stripe-connect/{facilityId}/account-session", true],
    stripeWebhook: ["post", "/stripe/webhook", false],
    changePassword: ["post", "/user/change-password", false],
    resetPassword: ["post", "/user/reset-password", false],
    createPassword: ["post", "/user/create-password", false],
    updateUser: ["put", "/user/{userId}", true],
    deleteUser: ["delete", "/user/{userId}", true],
    sendPasswordResetLink: ["put", "/user/{userId}/reset-password", true],
    getUserFacilities: ["get", "/user/{userId}/facilities", true],
    addUserToFacility: ["put", "/user/{userId}/facilities/{facilityId}", true],
    removeUserFromFacility: ["delete", "/user/{userId}/facilities/{facilityId}", true],
};
