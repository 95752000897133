import { ApiObjects } from "@pulso/api-client";
import { ComponentType } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "./useAuth";
import { Spinner } from "@/components/ui/spinner";

export function withParams<T extends string, P>(paramKeys: T[], Component: ComponentType<Record<T, string>>) {
  return function (props: P) {
    const params = useParams();

    const p: Record<T, string> = {} as Record<T, string>;

    for (const paramKey of paramKeys) {
      const val = params[paramKey];
      if (!val) {
        return null;
      }

      p[paramKey] = val;
    }

    return <Component {...props} {...p} />;
  };
}

export function withFacilityIdParam(Component: ComponentType<{ facilityId: string }>) {
  return withParams(["facilityId"], Component);
}

export function withFacility(Component: ComponentType<{ facility: ApiObjects["FacilityDto"] }>) {
  return function () {
    const { facility } = useAuth();

    if (!facility) {
      return <Spinner />;
    }

    return <Component facility={facility} />;
  };
}
