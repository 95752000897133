import { useCustomerFields } from "@/api/useCustomerFields";
import { Button } from "@/components/ui/button";
import { ApiObjects } from "@pulso/api-client";
import { useEffect, useRef, useState } from "react";
import { EditCustomerModal } from "./EditCustomerModal";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LanguageFlag } from "@pulso/components/lib/LanguageFlag";
import { useTranslation } from "react-i18next";
import { cn } from "@/lib/utils";
import { DateFormat } from "@/components/specific/DateFormat";

export function BookingCustomerDetails({ booking }: { booking: ApiObjects["BookingDto"] }) {
  const { fields } = useCustomerFields(booking.facilityId);
  const [showAll, setShowAll] = useState(false);
  const [isSmallWindow, setIsSmallWindow] = useState(false);
  const [allVisible, setAllVisible] = useState(false);
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLDivElement>(null);

  const data = [
    ...booking.customer.fields.map((field) => ({
      id: field.id,
      label: fields.find((f) => f.id === field.id)?.name,
      value: field.value,
      file: field.file,
      type: field.type,
    })),
  ].filter((l) => l.value);

  useEffect(() => {
    if (containerRef.current && itemsRef.current) {
      const containerEl = containerRef.current;
      const itemsEl = itemsRef.current;

      const obs = new ResizeObserver(() => {
        const containerHeight = containerEl.getBoundingClientRect().height;
        const bodyWidth = document.body.getBoundingClientRect().width;
        if (bodyWidth < 768) {
          setAllVisible(true);
          setIsSmallWindow(true);
        } else {
          const itemsHight = itemsEl.getBoundingClientRect().height;
          setAllVisible(containerHeight > itemsHight);
          setIsSmallWindow(false);
        }
      });

      obs.observe(containerEl);

      return () => obs.disconnect();
    }
  }, [fields, data]);

  return (
    <Card className="h-full flex flex-col pb-6">
      <CardHeader className="pb-3">
        <CardTitle>
          <div className="flex justify-between items-center">
            <div>
              <div className="flex items-center gap-2">
                {booking.customer.name} <LanguageFlag lang={booking.customer.language} />
              </div>
              {booking.customer.email && (
                <div>
                  <a href={`mailto:${booking.customer.email}`} className="text-muted-foreground font-normal text-sm">
                    {booking.customer.email}
                  </a>
                </div>
              )}
            </div>
            <div>
              <EditCustomerModal customer={booking.customer}>
                <Button size="sm" variant="secondary">
                  {t("common_button_edit", "Edit")}
                </Button>
              </EditCustomerModal>
            </div>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="relative overflow-hidden jsCardContent flex-1 pb-0" ref={containerRef}>
        <div
          className={cn("overflow-hidden transition-all jsItemsContent pb-9", !showAll && !isSmallWindow && "absolute")}
          ref={itemsRef}
        >
          <div className={cn("text-sm grid grid-cols-1 gap-x-3 space-y-1")}>
            {data.map((item) => (
              <div key={item.id}>
                <div className="text-muted-foreground">{item.label}</div>

                {item.file ? (
                  <div>
                    <a href={item.file?.url} target="_blank" className="external-link">
                      {t("customerInfo_fields_file_open_button", "Open file")}
                    </a>
                  </div>
                ) : item.type === "DATE" ? (
                  item.value ? (
                    <DateFormat date={item.value} format="date" />
                  ) : (
                    "-"
                  )
                ) : (
                  <div>{item.value}</div>
                )}
              </div>
            ))}
          </div>
        </div>
        {!isSmallWindow && !allVisible && (
          <div className="absolute bottom-0 right-0 bg-white pl-3 pr-6">
            <Button className="p-0 h-4" variant="link" onClick={() => setShowAll(!showAll)}>
              {showAll ? t("common_showLess", "Show less") : t("common_showMore", "Show more")}
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
