/* eslint-disable @typescript-eslint/no-explicit-any */
import { ItemsInput } from "@/components/specific/ItemsInput";
import { RichTextEditor } from "@/components/specific/RichTextEditor/RichTextEditor";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { MultiSelect } from "@/components/ui/multi-select";
import { PeriodInput } from "@/components/ui/period-input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { LanguagePicker } from "@pulso/components/lib/LanguagePicker";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { createContext, PropsWithChildren, ReactElement, useContext, useEffect } from "react";
import {
  ControllerRenderProps,
  DeepPartial,
  DefaultValues,
  EventType,
  FieldErrors,
  FieldPath,
  FieldValues,
  Path,
  SubmitHandler,
  useForm,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { ZodType, z } from "zod";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";
import { cn } from "@/lib/utils";
import { ApiObjects } from "@pulso/api-client";
import { FieldInput } from "./FieldInput";
import { TimePicker } from "../ui/time-picker";
import { ImageInput } from "../ui/image-input";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { InfoBoxWrap } from "./InfoBoxWrap";
import { PriceInput } from "../ui/price-input";
import { FileInput } from "@pulso/components/lib/FileInput";
import { DatePicker, DatePickerProps } from "../ui/date-picker";
import { SelectNative } from "@pulso/components/lib/SelectNative";
import { useTranslation } from "react-i18next";
import { formatDateTnLocalTimezone, notEmpty } from "@pulso/utils";
import { ButtonLoadable } from "../ui/button-loadable";

type FormFieldDefinition<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  type:
    | "string"
    | "text"
    | "select"
    | "selectNative"
    | "number"
    | "price"
    | "multi"
    | "richtext"
    | "period"
    | "items"
    | "password"
    | "switch"
    | "fields"
    | "time"
    | "image"
    | "file"
    | "radio"
    | "date"
    | "date-string"
    | "language"
    | "custom";
  label: string;
  placeholder?: string;
  options?: { label: string; value: string; typedValue?: string | boolean | object }[];
  languages?: string[];
  hideIf?: (values: any) => boolean;
  disableIf?: (values: any) => boolean;
  info?: string;
  disabled?: boolean;
  readOnly?: boolean;
  autoComplete?: string;
  className?: string;
  fields?: ApiObjects["ProductWithExtraDto"]["fields"];
  prefix?: string;
  datePickerOptions?: Omit<DatePickerProps, "mode">;
  language?: string;
  sign?: string;
  control?: (field: ControllerRenderProps<TFieldValues, TName>) => ReactElement;
  mandatory?: boolean;
};
type FormFieldsDefinition<TSchema extends ZodType> = {
  [index in keyof z.infer<TSchema>]?: index extends Path<keyof z.infer<TSchema>>
    ? FormFieldDefinition<z.infer<TSchema>, index>
    : never;
};

const context = createContext<{ fields: FormFieldsDefinition<ZodType>; extra?: any }>({ fields: {} });

export function PulsoForm(props: { noLables?: boolean; horizontal?: boolean }) {
  const form = useFormContext();

  const { fields: anyFields, extra } = useContext(context);

  if (!form) {
    return null;
  }

  const options = (fieldName: string) =>
    Array.isArray(extra?.[fieldName]?.options) ? extra[fieldName].options : anyFields[fieldName]?.options;

  const typedValue = (fieldName: string, value: string) => {
    const opt = options(fieldName).find((opt: any) => opt.value === value);
    return "typedValue" in opt ? opt.typedValue : value;
  };

  const stringValue = (fieldName: string, value: any) => {
    const opt = options(fieldName).find((opt: any) => opt.value === value || opt.typedValue === value);
    return opt?.value;
  };

  const onChangeWithRealValue = (fieldName: string, onChange: (typedValue: any) => void) => {
    return (value: string) => onChange(typedValue(fieldName, value));
  };

  return (
    <>
      {Object.entries(anyFields)
        .filter(([fieldName, fieldDef]) => fieldDef && (!fieldDef.hideIf || !fieldDef.hideIf(form.getValues())))
        .filter(notEmpty)
        .map(
          ([fieldName, fieldDef]) =>
            fieldDef &&
            (fieldDef.type === "fields" ? (
              (fieldDef.fields as ApiObjects["ProductWithExtraDto"]["fields"]).map((f) => (
                <div className={cn(fieldDef.className)} key={f.id} id={"formField_" + f.id}>
                  <FormField
                    control={form.control}
                    name={`fields.${f.id}` as any}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          {f.name} {fieldDef.mandatory && <span className="text-destructive _pl-2">*</span>}
                        </FormLabel>
                        <FormControl>
                          <FieldInput
                            type={f.type}
                            options={f.options}
                            value={field.value as null}
                            onChange={(v) => field.onChange(v)}
                            disabled={fieldDef.disabled}
                            placeholder={fieldDef.placeholder}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              ))
            ) : (
              <div className={cn(fieldDef.className)} key={fieldName} id={"formField_" + fieldName}>
                {fieldDef.type === "number" ? (
                  <FormField
                    control={form.control}
                    name={fieldName}
                    disabled={fieldDef.disabled}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          {fieldDef.label} {fieldDef.mandatory && <span className="text-destructive _pl-2">*</span>}
                        </FormLabel>
                        <FormControl>
                          <Input
                            {...form.register(fieldName as never, { valueAsNumber: true })}
                            {...(fieldDef.readOnly ? { readOnly: true } : {})}
                            disabled={fieldDef.disabled || fieldDef.disableIf?.(form.getValues())}
                            type="number"
                            prefix={fieldDef.prefix}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : (
                  <FormField
                    control={form.control}
                    name={fieldName as any}
                    disabled={fieldDef.disabled || fieldDef.disableIf?.(form.getValues())}
                    render={({ field }: any) => (
                      <FormItem
                        className={cn(
                          props.horizontal && "space-y-0",
                          !props.noLables && props.horizontal && "grid items-center grid-cols-form-row gap-3"
                        )}
                      >
                        {!props.noLables && (
                          <>
                            {fieldDef.info ? (
                              <InfoBoxWrap tooltip={fieldDef.info}>
                                <FormLabel>
                                  {fieldDef.label}{" "}
                                  {fieldDef.mandatory && <span className="text-destructive _pl-2">*</span>}
                                </FormLabel>
                              </InfoBoxWrap>
                            ) : (
                              <FormLabel>
                                {fieldDef.label}{" "}
                                {fieldDef.mandatory && <span className="text-destructive _pl-2">*</span>}
                              </FormLabel>
                            )}
                          </>
                        )}

                        {fieldDef.type === "string" && (
                          <FormControl>
                            <Input
                              {...field}
                              value={field.value === null ? "" : field.value}
                              autoComplete={fieldDef.autoComplete}
                            />
                          </FormControl>
                        )}
                        {fieldDef.type === "text" && (
                          <FormControl>
                            <Textarea {...field} value={field.value === null ? "" : field.value} rows={5} />
                          </FormControl>
                        )}
                        {fieldDef.type === "password" && (
                          <FormControl>
                            <Input {...field} type="password" />
                          </FormControl>
                        )}
                        {fieldDef.type === "switch" && (
                          <FormControl>
                            <div className="flex items-center space-x-2">
                              <Checkbox
                                id={"chk_" + field.name}
                                checked={field.value}
                                onCheckedChange={field.onChange}
                                disabled={field.disabled}
                              />
                              <Label htmlFor={"chk_" + field.name}>{fieldDef.placeholder}</Label>
                            </div>
                          </FormControl>
                        )}
                        {fieldDef.type === "richtext" && (
                          <FormControl>
                            <RichTextEditor
                              value={field.value}
                              onChange={field.onChange}
                              language={fieldDef.language || "es"}
                            />
                          </FormControl>
                        )}
                        {fieldDef.type === "multi" && (
                          <FormControl>
                            <MultiSelect {...field} options={options(fieldName)} />
                          </FormControl>
                        )}
                        {fieldDef.type === "period" && (
                          <FormControl>
                            <PeriodInput {...field} />
                          </FormControl>
                        )}
                        {fieldDef.type === "date" && (
                          <FormControl>
                            <div>
                              <DatePicker
                                mode="single"
                                {...field}
                                selected={field.value || undefined}
                                onSelect={(date) => field.onChange(date || null)}
                                {...(fieldDef.datePickerOptions || {})}
                              />
                            </div>
                          </FormControl>
                        )}
                        {fieldDef.type === "date-string" && (
                          <FormControl>
                            <div>
                              <Input
                                className="block"
                                {...field}
                                type="date"
                                value={field.value}
                                onChange={(e) => field.onChange(e.target.value)}
                              />
                            </div>
                          </FormControl>
                        )}
                        {fieldDef.type === "time" && (
                          <FormControl>
                            <div>
                              <TimePicker {...field} />
                            </div>
                          </FormControl>
                        )}
                        {fieldDef.type === "select" && (
                          <Select onValueChange={field.onChange} defaultValue={field.value} disabled={field.disabled}>
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder={fieldDef.placeholder} />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {options(fieldName).map((opt: any) => (
                                <SelectItem key={opt.value} value={opt.value}>
                                  {opt.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                        {fieldDef.type === "selectNative" && (
                          <SelectNative onChange={field.onChange} defaultValue={field.value} disabled={field.disabled}>
                            {options(fieldName).map((opt: any) => (
                              <option key={opt.value} value={opt.value}>
                                {opt.label}
                              </option>
                            ))}
                          </SelectNative>
                        )}
                        {fieldDef.type === "items" && (
                          <FormControl>
                            <div>
                              <ItemsInput {...field} />
                            </div>
                          </FormControl>
                        )}
                        {fieldDef.type === "image" && (
                          <FormControl>
                            <div>
                              <ImageInput {...field} />
                            </div>
                          </FormControl>
                        )}
                        {fieldDef.type === "price" && (
                          <FormControl>
                            <PriceInput {...field} sign={fieldDef.sign} />
                          </FormControl>
                        )}
                        {fieldDef.type === "file" && (
                          <FormControl>
                            <FileInput {...field} />
                          </FormControl>
                        )}
                        {fieldDef.type === "custom" && fieldDef.control && (
                          <FormControl>{fieldDef.control(field)}</FormControl>
                        )}
                        {fieldDef.type === "language" && (
                          <FormControl>
                            <LanguagePicker
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                              disabled={field.disabled}
                              languages={fieldDef.languages}
                            />
                          </FormControl>
                        )}
                        {fieldDef.type === "radio" && (
                          <RadioGroup
                            onValueChange={onChangeWithRealValue(fieldName, field.onChange)}
                            value={stringValue(fieldName, field.value)}
                            disabled={field.disabled}
                          >
                            {options(fieldName).map((opt: any) => (
                              <div key={opt.value} className="flex items-center space-x-2">
                                <RadioGroupItem
                                  disabled={field.disabled}
                                  value={opt.value}
                                  id={"id_" + field.name + "_" + opt.value}
                                  className="flex-shrink-0"
                                />
                                <Label
                                  className={cn(
                                    "font-normal text-sm",
                                    field.disabled ? "cursor-not-allowed" : "cursor-pointer"
                                  )}
                                  htmlFor={"id_" + field.name + "_" + opt.value}
                                >
                                  {opt.label}
                                </Label>
                              </div>
                            ))}
                          </RadioGroup>
                        )}

                        <FormMessage className={cn(props.horizontal && "col-start-2")} />
                      </FormItem>
                    )}
                  />
                )}
              </div>
            ))
        )}
    </>
  );
}

export function createForm<TSchema extends ZodType>(fields: FormFieldsDefinition<NoInfer<TSchema>>, schema: TSchema) {
  return { schema, fields };
}

export function PulsoFormProvider<TSchema extends ZodType, TD extends z.infer<TSchema>>(
  props: PropsWithChildren<{
    fields: FormFieldsDefinition<TSchema>;
    schema: TSchema;
    initialValues?: DefaultValues<NoInfer<TD>>;
    values?: NoInfer<TD>;
    onSubmit: SubmitHandler<NoInfer<TD>>;
    onChange?: (
      value: DeepPartial<NoInfer<TD>>,
      info: {
        name?: FieldPath<NoInfer<TD>>;
        type?: EventType;
      },
      form: UseFormReturn<NoInfer<TD>>
    ) => void;
    extra?: any;
    className?: string;
    fieldsClassName?: string;
    buttonWrapClassName?: string;
    dontAutoRenderForm?: boolean;
    isLoading?: boolean;
    extraButtons?: ReactElement;
    buttonText?: string;
    horizontal?: boolean;
  }>
) {
  const { t } = useTranslation();

  const form = useForm<TD>({
    resolver: zodResolver(props.schema),
    defaultValues: props.initialValues,
  });

  useEffect(() => {
    if (typeof props.onChange === "function") {
      const sub = form.watch((values, info) => {
        props.onChange?.(values, info, form);
      });
      return () => sub.unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (props.values) {
      Object.keys(props.values).forEach((key) => {
        form.setValue(key as Path<TD>, props.values?.[key]);
      });
    }
  }, [props.values]);

  return (
    <Form {...form}>
      <form className={cn("relative", props.className)} onSubmit={form.handleSubmit(props.onSubmit, onError)}>
        <context.Provider value={{ fields: props.fields as any, extra: props.extra }}>
          <div className={cn("flex flex-col gap-3", props.fieldsClassName)}>
            {props.dontAutoRenderForm ? (
              props.children
            ) : (
              <>
                <PulsoForm horizontal={props.horizontal} />
                {props.children}
              </>
            )}
          </div>

          <div className={cn("flex justify-start items-center mt-6 gap-3", props.buttonWrapClassName)}>
            <ButtonLoadable isLoading={props.isLoading}>
              {props.buttonText || t("common_button_save", "Save")}
            </ButtonLoadable>
            {props.extraButtons}
          </div>
        </context.Provider>
      </form>
    </Form>
  );

  function onError(errors: FieldErrors<TD>) {
    const firstError = Object.keys(errors)[0];
    if (firstError) {
      document.getElementById(`formField_${firstError}`)?.scrollIntoView();
    }
  }
}
